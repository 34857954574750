<template>
  <div>
    <b-card class="text-center">
      <h1><feather-icon
        class="mr-2"
        icon="ClipboardIcon"
        size="25"
      />Richieste Ferie</h1>
    </b-card>
    <b-card no-body>
      <div class="m-1">
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
            class="mb-1"
          >
            <label class="d-flex justify-content-between">
              Tipo
              <b-badge
                variant="primary"
                :to="{ name: 'apps-justifications-types' }"
              > Gestisci Tipi </b-badge>
            </label>
            <v-select
              v-model="filterType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="types"
              class="w-100"
              @input="refetchJustifications()"
            />
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
            class="mb-1"
          >
            <label>Stato</label>
            <v-select
              v-model="filterStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              class="w-100"
              @input="refetchJustifications()"
            />
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
            class="mb-1"
          >
            <label>Reparto</label>
            <v-select
              v-model="filterDepartment"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="departments"
              class="w-100"
              @input="refetchJustifications()"
            />
          </b-col>
          <b-col
            v-if="subDepartments && subDepartments.length"
            cols="12"
            md="6"
            lg="4"
            class="mb-1"
          >
            <label>Sottoreparto</label>
            <v-select
              v-model="filterSubDepartment"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="subDepartments"
              class="w-100"
              @input="refetchJustifications()"
            />
          </b-col>
          <b-col
            cols="12"
            md="12"
            lg="12"
            class="text-right"
          >
            <b-button
              variant="primary"
              @click="$bvModal.show('create-modal')"
            >
              Nuova Richiesta Ferie
            </b-button>
          </b-col>
          <b-col
            cols="12"
            class="mt-3"
          >
            <b-carousel
              id="month-slider"
              ref="monthSlider"
              v-model="selectedMonth"
              :interval="0"
              controls
              background="#ababab"
              @sliding-start="handleSlideStart()"
            >
              <b-carousel-slide
                v-for="month in months"
                :key="month"
                img-blank
              ><h2>{{ month }} {{ currentYear }}</h2>
              </b-carousel-slide>
            </b-carousel>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-row>
      <b-col cols="1" />
      <b-col
        :key="listKey"
        cols="10"
      >
        <b-card
          v-for="(justification, index) in justifications"
          :key="justification.id"
          no-body
          style="border-radius: 30px"
        >
          <b-card-title
            class="d-flex justify-content-center align-items-center p-1"
            style="margin-bottom: 0"
          >
            <h2
              class="text-capitalize"
              style="margin-bottom: 0"
            >
              {{ justification.type }}
            </h2>
            <span
              :id="'status-tooltip-' + justification.id"
              class="align-text-top text-capitalize ml-2"
            >
              <feather-icon
                size="15"
                :icon="justificationsStatusIcon(justification.status)"
                :class="[justificationsStatusColor(justification.status), 'mr-1']"
              />
              <small>{{ justificationsStatus(justification.status) }}</small>
              <small
                v-if="justification.status == 'waiting' && justification.approvation_type =='two_managers'"
                class="text-warning"
              > (Responsabili)</small>
              <small
                v-if="justification.status == 'approved' && justification.approvation_type =='admin' && justification.type != 'Ferie Approvate'"
                class="text-warning"
              > (Admin)</small>
              <small
                v-if="justification.status == 'approved' && justification.approvation_type =='admin' && justification.type == 'Ferie Approvate'"
                class="text-warning"
              > (Automatico)</small>
              <small
                v-if="justification.status == 'approved' && justification.approvation_type =='two_managers'"
                class="text-warning"
              > (Responsabili)</small>
              <small
                v-if="justification.status == 'rejected' && justification.approvation_type =='admin'"
                class="text-warning"
              > (Admin)</small>
              <small
                v-if="justification.status == 'rejected' && justification.approvation_type =='two_managers'"
                class="text-warning"
              > (Responsabili)</small>
            </span>
          </b-card-title>
          <div
            v-if="justification.status == 'approved' && justification.approvation_type == 'two_managers'"
            class="d-flex align-items-center justify-content-center"
          >
            <b-avatar
              size="15"
              :src="justification.approved_first_user && justification.approved_first_user.avatar_file ? mediaUrl + justification.approved_first_user.avatar_file : ''"
              :text="avatarText(justification.approved_first_user.display_name)"
              :variant="`light-${resolveUserRoleVariant(justification.approved_first_user.role_code)}`"
              :to="{ name: 'apps-users-view', params: { id: justification.approved_first_user.id } }"
              class="mr-1"
            />
            <b-link
              :to="{ name: 'apps-users-view', params: { id: justification.approved_first_user.id } }"
              class="d-block text-nowrap"
            >
              <small>
                #{{ justification.approved_first_user.anagrafico_id }} - {{ justification.approved_first_user.display_name }}
              </small>
            </b-link>
            <feather-icon
              size="10"
              icon="CheckCircleIcon"
              class="text-success ml-1"
            />
          </div>
          <div
            v-if="justification.status == 'approved' && justification.approvation_type == 'two_managers' && justification.approved_second_user"
            class="d-flex align-items-center justify-content-center"
          >
            <b-avatar
              size="15"
              :src="justification.approved_second_user && justification.approved_second_user.avatar_file ? mediaUrl + justification.approved_second_user.avatar_file : ''"
              :text="avatarText(justification.approved_second_user.display_name)"
              :variant="`light-${resolveUserRoleVariant(justification.approved_second_user.role_code)}`"
              :to="{ name: 'apps-users-view', params: { id: justification.approved_second_user.id } }"
              class="mr-1"
            />
            <b-link
              :to="{ name: 'apps-users-view', params: { id: justification.approved_second_user.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              <small>
                #{{ justification.approved_second_user.anagrafico_id }} - {{ justification.approved_second_user.display_name }}
              </small>
            </b-link>
            <feather-icon
              size="10"
              icon="CheckCircleIcon"
              class="text-success ml-1"
            />
          </div>
          <div
            v-if="justification.status == 'rejected' && justification.approvation_type == 'two_managers' && justification.approved_first_user"
            class="d-flex align-items-center justify-content-center"
          >
            <b-avatar
              size="15"
              :src="justification.approved_first_user && justification.approved_first_user.avatar_file ? mediaUrl + justification.approved_first_user.avatar_file : ''"
              :text="avatarText(justification.approved_first_user.display_name)"
              :variant="`light-${resolveUserRoleVariant(justification.approved_first_user.role_code)}`"
              :to="{ name: 'apps-users-view', params: { id: justification.approved_first_user.id } }"
              class="mr-1"
            />
            <b-link
              :to="{ name: 'apps-users-view', params: { id: justification.approved_first_user.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              <small>
                #{{ justification.approved_first_user.anagrafico_id }} - {{ justification.approved_first_user.display_name }}
              </small>
            </b-link>
            <feather-icon
              size="10"
              icon="CheckCircleIcon"
              class="text-success ml-1"
            />
          </div>
          <div
            v-if="justification.status == 'rejected' && justification.approvation_type == 'two_managers'"
            class="d-flex align-items-center justify-content-center"
          >
            <b-avatar
              size="15"
              :src="justification.rejected_user ? mediaUrl + justification.rejected_user.avatar_file : ''"
              :text="avatarText(justification.rejected_user.display_name)"
              :variant="`light-${resolveUserRoleVariant(justification.rejected_user.role_code)}`"
              :to="{ name: 'apps-users-view', params: { id: justification.rejected_user.id } }"
              class="mr-1"
            />
            <b-link
              :to="{ name: 'apps-users-view', params: { id: justification.rejected_user.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              <small>
                #{{ justification.rejected_user.anagrafico_id }} - {{ justification.rejected_user.display_name }}
              </small>
            </b-link>
            <feather-icon
              size="10"
              icon="XCircleIcon"
              class="text-danger ml-1"
            />
          </div>
          <div
            v-if="justification.status == 'rejected' &&
              justification.approvation_type == 'two_managers' &&
              justification.user.approvator1_user_id != justification.rejected_user_id &&
              justification.user.approvator1_user_id != justification.approved_first_user
            "
            class="d-flex align-items-center justify-content-center"
          >
            <b-avatar
              size="15"
              :src="justification.user.approvator1_user ? mediaUrl + justification.user.approvator1_user.avatar_file : ''"
              :text="avatarText(justification.user.approvator1_user.display_name)"
              :variant="`light-${resolveUserRoleVariant(justification.user.approvator1_user.role_code)}`"
              :to="{ name: 'apps-users-view', params: { id: justification.user.approvator1_user.id } }"
              class="mr-1"
            />
            <b-link
              :to="{ name: 'apps-users-view', params: { id: justification.user.approvator1_user.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              <small>
                #{{ justification.user.approvator1_user.anagrafico_id }} - {{ justification.user.approvator1_user.display_name }}
              </small>
            </b-link>
            <feather-icon
              size="10"
              icon="PauseCircleIcon"
              class="text-secondary ml-1"
            />
          </div>
          <div
            v-if="justification.status == 'rejected' &&
              justification.approvation_type == 'two_managers' &&
              justification.user.approvator2_user_id != justification.rejected_user_id &&
              justification.user.approvator2_user_id != justification.approved_first_user_id
            "
            class="d-flex align-items-center justify-content-center"
          >
            <b-avatar
              size="15"
              :src="justification.user.approvator2_user ? mediaUrl + justification.user.approvator2_user.avatar_file : ''"
              :text="avatarText(justification.user.approvator2_user.display_name)"
              :variant="`light-${resolveUserRoleVariant(justification.user.approvator2_user.role_code)}`"
              :to="{ name: 'apps-users-view', params: { id: justification.user.approvator2_user.id } }"
              class="mr-1"
            />
            <b-link
              :to="{ name: 'apps-users-view', params: { id: justification.user.approvator2_user.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              <small>
                #{{ justification.user.approvator2_user.anagrafico_id }} - {{ justification.user.approvator2_user.display_name }}
              </small>
            </b-link>
            <feather-icon
              size="10"
              icon="PauseCircleIcon"
              class="text-secondary ml-1"
            />
          </div>
          <div v-if="justification.status == 'waiting' && justification.approvation_type == 'two_managers' && justification.approved_first_user">
            <div class="d-flex align-items-center justify-content-center">
              <b-avatar
                size="15"
                :src="justification.approved_first_user ? mediaUrl + justification.approved_first_user.avatar_file : ''"
                :text="avatarText(justification.approved_first_user.display_name)"
                :variant="`light-${resolveUserRoleVariant(justification.approved_first_user.role_code)}`"
                :to="{ name: 'apps-users-view', params: { id: justification.approved_first_user.id } }"
                class="mr-1"
              />
              <b-link
                :to="{ name: 'apps-users-view', params: { id: justification.approved_first_user.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                <small>
                  #{{ justification.approved_first_user.anagrafico_id }} - {{ justification.approved_first_user.display_name }}
                </small>
              </b-link>
              <feather-icon
                size="10"
                icon="CheckCircleIcon"
                class="text-success ml-1"
              />
            </div>
          </div>
          <div
            v-if="justification.status == 'waiting' &&
              justification.approvation_type == 'two_managers' &&
              justification.user.approvator1_user_id != justification.rejected_user_id &&
              justification.user.approvator1_user_id != justification.approved_first_user_id
            "
            class="d-flex align-items-center justify-content-center"
          >
            <b-avatar
              size="15"
              :src="justification.user.approvator1_user ? mediaUrl + justification.user.approvator1_user.avatar_file : ''"
              :text="avatarText(justification.user.approvator1_user.display_name)"
              :variant="`light-${resolveUserRoleVariant(justification.user.approvator1_user.role_code)}`"
              :to="{ name: 'apps-users-view', params: { id: justification.user.approvator1_user.id } }"
              class="mr-1"
            />
            <b-link
              :to="{ name: 'apps-users-view', params: { id: justification.user.approvator1_user.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              <small>
                #{{ justification.user.approvator1_user.anagrafico_id }} - {{ justification.user.approvator1_user.display_name }}
              </small>
            </b-link>
            <feather-icon
              size="10"
              icon="PauseCircleIcon"
              class="text-secondary ml-1"
            />
          </div>
          <div
            v-if="justification.status == 'waiting' &&
              justification.approvation_type == 'two_managers' &&
              justification.user.approvator2_user_id != justification.rejected_user_id &&
              justification.user.approvator2_user_id != justification.approved_first_user_id
            "
            class="d-flex align-items-center justify-content-center"
          >
            <b-avatar
              size="15"
              :src="justification.user.approvator2_user ? mediaUrl + justification.user.approvator2_user.avatar_file : ''"
              :text="avatarText(justification.user.approvator2_user.display_name)"
              :variant="`light-${resolveUserRoleVariant(justification.user.approvator2_user.role_code)}`"
              :to="{ name: 'apps-users-view', params: { id: justification.user.approvator2_user.id } }"
              class="mr-1"
            />
            <b-link
              :to="{ name: 'apps-users-view', params: { id: justification.user.approvator2_user.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              <small>
                #{{ justification.user.approvator2_user.anagrafico_id }} - {{ justification.user.approvator2_user.display_name }}
              </small>
            </b-link>
            <feather-icon
              size="10"
              icon="PauseCircleIcon"
              class="text-secondary ml-1"
            />
          </div>
          <div
            class="d-flex justify-content-between align-items-center pl-3 pr-3 pb-1 pt-1"
            style="width: 100%; padding: 0 3rem"
          >
            <div class="d-flex align-items-center">
              <b-avatar
                size="30"
                :src="justification.user.avatar_file ? mediaEndpoint + justification.user.avatar_file : ''"
                :text="avatarText(justification.user.display_name)"
                :variant="`light-${resolveUserRoleVariant(justification.user.role_code)}`"
                :to="{ name: 'apps-users-view', params: { id: justification.user.id } }"
              />
              <b-link
                :to="{ name: 'apps-users-view', params: { id: justification.user.id } }"
                class="font-weight-bold d-block text-nowrap ml-2"
              >
                #{{ justification.user.anagrafico_id }} - {{ justification.user.display_name }}
              </b-link>
            </div>
            <div v-if="justification.type != 'Ferie Approvate'">
              <b-button
                v-b-toggle="'collapse-' + justification.id"
                pill
                size="sm"
                variant="primary"
              >
                Visualizza Note
              </b-button>
              <b-button
                v-if="justification.status == 'waiting' && justification.approvation_type != 'two_managers'"
                title="Approva"
                size="sm"
                variant="success"
                class="ml-1 rounded-circle"
                style="padding: 6px"
                @click="
                  $bvModal.show('approve-modal')
                  selectedJustification = justification.id
                "
              >
                <feather-icon
                  icon="CheckIcon"
                  size="15"
                />
              </b-button>
              <b-button
                title="Modifica"
                size="sm"
                variant="warning"
                class="ml-1 rounded-circle"
                style="padding: 6px"
                @click="
                  updatedJustificationUser = {label: '#' + justification.user.anagrafico_id + ' - ' + justification.user.display_name, value: justification.user.id},
                  updatedJustificationType = {label: justification.type.charAt(0).toUpperCase() + justification.type.slice(1), value: justification.type}
                  updatedAllDay = justification.all_day == 1 ? true : false
                  setUpdatedJustificationMultipleDay(justification)
                  updatedJustification = Object.assign({}, justification)
                  $bvModal.show('update-modal')
                "
              >
                <feather-icon
                  icon="Edit2Icon"
                  size="15"
                />
              </b-button>
              <b-button
                title="Elimina"
                size="sm"
                variant="danger"
                class="ml-1 rounded-circle"
                style="padding: 6px"
                @click="
                  $bvModal.show('delete-modal')
                  selectedJustification = justification.id
                "
              >
                <feather-icon
                  icon="Trash2Icon"
                  size="15"
                />
              </b-button>
            </div>
          </div>
          <b-collapse
            :id="'collapse-' + justification.id"
            class="mt-2 pl-3 pr-3"
          >
            <b-card>
              <b-card-text>
                <div v-if="justification.justification_proposal_id">
                  <b-link
                    :to="{ name: 'apps-justificationproposals-view', params: { id: justification.justification_proposal_id } }"
                    class="font-weight-bold d-block text-nowrap text-info text-center"
                  >
                    Proposta #{{ justification.justification_proposal_id }}
                  </b-link>
                </div>
                <div v-if="justification.note">
                  <h6 class="mt-2">
                    Note:
                  </h6>
                  <div
                    class="rounded-lg p-1"
                    style="background-color: #161d31"
                  >
                    <p class="m-0">
                      {{ justification.note }}
                    </p>
                  </div>
                </div>
                <div>
                  <h6 class="mt-2">
                    Note Amministrazione:
                  </h6>
                  <div
                    class="rounded-lg"
                    style="background-color: #161d31"
                  >
                    <b-form-textarea
                      v-model="justification.manager_note"
                      class="m-0"
                    />
                  </div>
                  <div class="text-right mt-1">
                    <b-button
                      pill
                      size="sm"
                      variant="primary"
                      @click="updateNote(justification.id, index)"
                    >
                      Salva Note Admin
                    </b-button>
                  </div>
                </div>
              </b-card-text>
            </b-card>
          </b-collapse>
          <template #footer>
            <p
              v-if="justification.start_day == justification.end_day && !justification.all_day"
              class="mb-0"
            >
              {{ parseDate(justification.start_day) }} {{ justification.start_time.substring(0,5) }} - {{ justification.end_time.substring(0,5) }}
            </p>
            <p
              v-if="justification.start_day == justification.end_day && justification.all_day"
              class="mb-0"
            >
              {{ parseDate(justification.start_day) }}
            </p>
            <p
              v-if="justification.start_day != justification.end_day && !justification.all_day"
              class="mb-0"
            >
              {{ parseDate(justification.start_day) }} {{ justification.start_time.substring(0,5) }} - {{ parseDate(justification.end_day) }} {{ justification.end_time.substring(0,5) }}
            </p>
            <p
              v-if="justification.start_day != justification.end_day && justification.all_day"
              class="mb-0"
            >
              {{ parseDate(justification.start_day) }} - {{ parseDate(justification.end_day) }}
            </p>
          </template>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="create-modal"
      title="Nuova Richiesta Ferie"
      size="lg"
      footer-class="d-flex justify-content-end"
      centered
    >
      <template #modal-header="{ close }">
        <h5 class="mb-0">
          Nuova Richiesta Ferie
        </h5>
        <feather-icon
          icon="XIcon"
          size="18"
          @click="close()"
        />
      </template>
      <template>
        <b-form>
          <b-row class="mt-2 mb-2">
            <b-col
              cols="12"
              lg="6"
              class="mb-1"
            >
              <b-form-group
                label="Tipo di Giustificativo *"
                label-for="type"
              >
                <v-select
                  id="type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="types"
                  @input="setType($event)"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              lg="6"
              class="mb-1"
            >
              <b-form-group
                label="Seleziona Utente *"
                label-for="user_id"
              >
                <v-select
                  id="user_id"
                  :filterable="false"
                  :options="usersPaginated"
                  @open="onOpen"
                  @close="onClose"
                  @search="(query) => (search = query)"
                  @input="selectUser($event)"
                >
                  <template #list-footer>
                    <li
                      v-show="hasNextPage"
                      ref="load"
                      class="loader"
                    >
                      Carica più Utenti...
                    </li>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="mb-1"
            >
              <b-form-checkbox
                v-model="multipleDay"
                switch
              >
                Giustificativo relativo a più giorni
              </b-form-checkbox>
            </b-col>
            <b-col
              cols="12"
              :md="multipleDay ? '6' : '8'"
              :lg="multipleDay ? '6' : '8'"
              class="mb-1"
            >
              <b-form-group
                label="Data di Inizio *"
                label-for="start_day"
              >
                <b-form-datepicker
                  id="start_day"
                  v-model="newJustification.start_day"
                  placeholder="Scegli Data"
                  locale="it"
                  :min="fromDay"
                  today-button
                  reset-button
                  close-button
                  label-today-button="Oggi"
                  label-reset-button="Annulla"
                  label-close-button="Chiudi"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                  label-help="Scegli la data di Inizio"
                  label-no-date-selected="Nessuna data selezionata"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="multipleDay"
              cols="12"
              md="6"
              lg="6"
              class="mb-1"
            >
              <b-form-group
                label="Data di Fine *"
                label-for="end_day"
              >
                <b-form-datepicker
                  id="end_day"
                  v-model="newJustification.end_day"
                  placeholder="Scegli Data"
                  locale="it"
                  :min="minToDay"
                  :date-disabled-fn="dateDisabledNew"
                  today-button
                  reset-button
                  close-button
                  label-today-button="Oggi"
                  label-reset-button="Annulla"
                  label-close-button="Chiudi"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                  label-help="Scegli la data di Inizio"
                  label-no-date-selected="Nessuna data selezionata"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
              lg="4"
              class="mb-1"
            >
              <b-form-group
                label="Tutto il Giorno *"
                label-for="all_day"
              >
                <b-form-checkbox
                  id="all_day"
                  v-model="allDay"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="!allDay"
              cols="12"
              :md="multipleDay ? '4' : '6'"
              :lg="multipleDay ? '4' : '6'"
              class="mb-1"
            >
              <b-form-group
                label="Dalle ore *"
                label-for="start_time"
              >
                <b-form-timepicker
                  id="start_time"
                  v-model="newJustification.start_time"
                  locale="it"
                  placeholder="Orario"
                  reset-button
                  label-reset-button="Annulla"
                  label-close-button="Chiudi"
                  label-no-time-selected="Seleziona orario"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="!allDay"
              cols="12"
              :md="multipleDay ? '4' : '6'"
              :lg="multipleDay ? '4' : '6'"
              class="mb-1"
            >
              <b-form-group
                label="Alle Ore *"
                label-for="end_time"
              >
                <b-form-timepicker
                  id="end_time"
                  v-model="newJustification.end_time"
                  locale="it"
                  placeholder="Orario"
                  reset-button
                  label-reset-button="Annulla"
                  label-close-button="Chiudi"
                  label-no-time-selected="Seleziona orario"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              clas="mb-1"
            >
              <b-form-group
                label="Note"
                label-for="note"
              >
                <b-form-textarea
                  id="note"
                  v-model="newJustification.note"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              clas="mb-1"
            >
              <b-form-group
                label="Note Amministrazione"
                label-for="manager_note"
              >
                <b-form-textarea
                  id="manager_note"
                  v-model="newJustification.manager_note"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-danger"
          @click="cancel()"
        >
          Annulla
        </b-button>
        <b-button
          variant="success"
          @click="createJustification()"
        >
          Conferma
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="update-modal"
      title="Modifica Richiesta Ferie"
      size="lg"
      footer-class="d-flex justify-content-end"
      centered
    >
      <template #modal-header="{ close }">
        <h5 class="mb-0">
          Modifica Richiesta Ferie
        </h5>
        <feather-icon
          icon="XIcon"
          size="18"
          @click="close()"
        />
      </template>
      <template>
        <b-form>
          <b-row class="mt-2 mb-2">
            <b-col
              cols="12"
              lg="6"
              class="mb-1"
            >
              <b-form-group
                label="Tipo di Giustificativo *"
                label-for="type"
              >
                <v-select
                  id="type"
                  v-model="updatedJustificationType"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="types"
                  @input="setUpdatedType($event)"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              lg="6"
              class="mb-1"
            >
              <b-form-group
                label="Seleziona Utente *"
                label-for="user_id"
              >
                <v-select
                  id="user_id"
                  v-model="updatedJustificationUser"
                  :filterable="false"
                  :options="usersPaginated"
                  @open="onOpen"
                  @close="onClose"
                  @search="(query) => (search = query)"
                  @input="selectUpdatedUser($event)"
                >
                  <template #list-footer>
                    <li
                      v-show="hasNextPage"
                      ref="load"
                      class="loader"
                    >
                      Carica più Utenti...
                    </li>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="mb-1"
            >
              <b-form-checkbox
                v-model="updatedMultipleDay"
                switch
              >
                Giustificativo relativo a più giorni
              </b-form-checkbox>
            </b-col>
            <b-col
              cols="12"
              :md="updatedMultipleDay ? '6' : '8'"
              :lg="updatedMultipleDay ? '6' : '8'"
              class="mb-1"
            >
              <b-form-group
                label="Data di Inizio *"
                label-for="start_day"
              >
                <b-form-datepicker
                  id="start_day"
                  v-model="updatedJustification.start_day"
                  placeholder="Scegli Data"
                  locale="it"
                  :min="fromDay"
                  today-button
                  reset-button
                  close-button
                  label-today-button="Oggi"
                  label-reset-button="Annulla"
                  label-close-button="Chiudi"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                  label-help="Scegli la data di Inizio"
                  label-no-date-selected="Nessuna data selezionata"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="updatedMultipleDay"
              cols="12"
              md="6"
              lg="6"
              class="mb-1"
            >
              <b-form-group
                label="Data di Fine *"
                label-for="end_day"
              >
                <b-form-datepicker
                  id="end_day"
                  v-model="updatedJustification.end_day"
                  placeholder="Scegli Data"
                  locale="it"
                  :date-disabled-fn="dateDisabledUpdate"
                  today-button
                  reset-button
                  close-button
                  label-today-button="Oggi"
                  label-reset-button="Annulla"
                  label-close-button="Chiudi"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                  label-help="Scegli la data di Fine"
                  label-no-date-selected="Nessuna data selezionata"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
              lg="4"
              class="mb-1"
            >
              <b-form-group
                label="Tutto il Giorno *"
                label-for="all_day"
              >
                <b-form-checkbox
                  id="all_day"
                  v-model="updatedAllDay"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="!updatedAllDay"
              cols="12"
              :md="updatedMultipleDay ? '4' : '6'"
              :lg="updatedMultipleDay ? '4' : '6'"
              class="mb-1"
            >
              <b-form-group
                label="Dalle ore *"
                label-for="start_time"
              >
                <b-form-timepicker
                  id="start_time"
                  v-model="updatedJustification.start_time"
                  locale="it"
                  placeholder="Orario"
                  reset-button
                  label-reset-button="Annulla"
                  label-close-button="Chiudi"
                  label-no-time-selected="Seleziona orario"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="!updatedAllDay"
              cols="12"
              :md="updatedMultipleDay ? '4' : '6'"
              :lg="updatedMultipleDay ? '4' : '6'"
              class="mb-1"
            >
              <b-form-group
                label="Alle Ore *"
                label-for="end_time"
              >
                <b-form-timepicker
                  id="end_time"
                  v-model="updatedJustification.end_time"
                  locale="it"
                  placeholder="Orario"
                  reset-button
                  label-reset-button="Annulla"
                  label-close-button="Chiudi"
                  label-no-time-selected="Seleziona orario"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              clas="mb-1"
            >
              <b-form-group
                label="Note"
                label-for="note"
              >
                <b-form-textarea
                  id="note"
                  v-model="updatedJustification.note"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              clas="mb-1"
            >
              <b-form-group
                label="Note Amministrazione"
                label-for="manager_note"
              >
                <b-form-textarea
                  id="manager_note"
                  v-model="updatedJustification.manager_note"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-danger"
          @click="cancel()"
        >
          Annulla
        </b-button>
        <b-button
          variant="success"
          @click="updateJustification()"
        >
          Conferma
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="delete-modal"
      title="Cancella Richiesta Ferie"
      footer-class="d-flex justify-content-end"
      body-class="p-0"
      size="sm"
      centered
    >
      <template #modal-header="{ close }">
        <h5 class="mb-0">
          Cancella Richiesta Ferie
        </h5>
        <feather-icon
          icon="XIcon"
          size="18"
          @click="close()"
        />
      </template>
      <p class="m-2">
        Sei sicuro di voler cancellare definitivamente questo Giustificativo e i suoi dati correlati?
      </p>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          Annulla
        </b-button>
        <b-button
          size="sm"
          variant="success"
          @click="
            ok()
            deleteJustification(selectedJustification)
          "
        >
          OK
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="approve-modal"
      title="Approva Richiesta Ferie"
      footer-class="d-flex justify-content-end"
      body-class="p-0"
      size="sm"
      centered
    >
      <template #modal-header="{ close }">
        <h5 class="mb-0">
          Approva Richiesta Ferie
        </h5>
        <feather-icon
          icon="XIcon"
          size="18"
          @click="close()"
        />
      </template>
      <div class="p-2">
        <b-form-group
          v-slot="{ ariaDescribedby }"
          label="Scegli il tipo di Approvazione"
        >
          <b-form-radio
            v-model="approveType"
            class="mt-1"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            value="admin"
          ><span class="text-success">Approvazione Admin</span></b-form-radio>
          <b-form-radio
            v-model="approveType"
            class="mt-1"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            value="two_managers"
          ><span class="text-warning">Approvazione Responsabili di Reparto</span></b-form-radio>
          <b-form-radio
            v-model="approveType"
            class="mt-1"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            value="reject"
          ><span class="text-danger">Rifiuto Admin</span></b-form-radio>
        </b-form-group>
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          Annulla
        </b-button>
        <b-button
          size="sm"
          variant="success"
          @click="selectApprovation(selectedJustification)"
        >
          OK
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCarousel,
  BCarouselSlide,
  BAvatar,
  BLink,
  BCardTitle,
  BButton,
  BCollapse,
  BCardText,
  BFormTextarea,
  BForm,
  BFormSelect,
  BFormGroup,
  BFormDatepicker,
  BFormTimepicker,
  BFormCheckbox,
  BFormRadio,
  BBadge,
  BTooltip,
  VBToggle,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted, watch } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import userStoreModule from '@/views/apps/user/userStoreModule'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import departmentsStoreModule from '@/views/apps/departments/departmentsStoreModule'
import justificationsStoreModule from '../justificationsStoreModule'
import useJustificationsList from './useJustificationsList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCarousel,
    BCarouselSlide,
    BAvatar,
    BLink,
    BCardTitle,
    BButton,
    BCollapse,
    BCardText,
    BFormTextarea,
    BForm,
    BFormSelect,
    BFormGroup,
    BFormDatepicker,
    BFormTimepicker,
    BFormCheckbox,
    BFormRadio,
    BBadge,
    BTooltip,
    vSelect,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    const JUSTIFICATIONS_APP_STORE_MODULE_NAME = 'app-justifications'
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const DEPARTMENTS_APP_STORE_MODULE_NAME = 'app-departments'

    // Register module
    if (!store.hasModule(JUSTIFICATIONS_APP_STORE_MODULE_NAME)) {
      store.registerModule(JUSTIFICATIONS_APP_STORE_MODULE_NAME, justificationsStoreModule)
    }
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }
    if (!store.hasModule(DEPARTMENTS_APP_STORE_MODULE_NAME)) {
      store.registerModule(DEPARTMENTS_APP_STORE_MODULE_NAME, departmentsStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(JUSTIFICATIONS_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(JUSTIFICATIONS_APP_STORE_MODULE_NAME)
      }
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      if (store.hasModule(DEPARTMENTS_APP_STORE_MODULE_NAME)) store.unregisterModule(DEPARTMENTS_APP_STORE_MODULE_NAME)
    })

    const months = [
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre',
    ]
    const momentMonth = moment().format('M')
    const currentMonth = momentMonth - 1
    const previousMonth = momentMonth - 1
    const currentDate = moment()
    const initialMonth = currentDate.month() // Ottieni il mese corrente (0-11)
    const initialYear = currentDate.year()
    const fromDay = currentDate.startOf('month').format('YYYY-MM-DD')
    const toDay = currentDate.endOf('month').format('YYYY-MM-DD')

    const justifications = ref(null)
    store
      .dispatch('app-justifications/fetchJustifications', {
        type: null,
        status: null,
        sort_by: 'created_at',
        sort_direction: 'DESC',
        from_start_day: fromDay,
        to_start_day: toDay,
        per_page: 1000,
        page: 0,
      })
      .then(response => {
        justifications.value = response.data[0]
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })

    const statusOptions = [
      { label: 'In Attesa di Approvazione', value: 'waiting' },
      { label: 'Approvato', value: 'approved' },
      { label: 'Rifiutato', value: 'rejected' },
    ]

    const mediaEndpoint = process.env.VUE_APP_MEDIA_URL

    const departments = ref(null)
    const _departments = []
    store
      .dispatch('app-departments/fetchDepartments', { root: true })
      .then(response => {
        response.data[0].forEach((department, i) => {
          const _department = {
            label: `#${department.id} - ${department.name}`,
            value: department.id,
          }
          _departments.push(_department)
        })
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })
    departments.value = _departments

    const filterDepartment = ref(null)
    const filterSubDepartment = ref(null)
    const subDepartments = ref([])

    watch(filterDepartment, () => {
      filterSubDepartment.value = null
      if (!filterDepartment.value) {
        subDepartments.value = []
        return
      }
      store
        .dispatch('app-departments/fetchDepartments', {
          parent_department_id: filterDepartment.value.value,
        })
        .then(response => {
          subDepartments.value = response.data[0].map(department => ({
            label: `#${department.id} - ${department.name}`,
            value: department.id,
          }))
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    })

    const {
      types,
      users,
      resolveUserRoleName,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      justificationsStatus,
      justificationsStatusColor,
      justificationsStatusIcon,
      parseDate,
      mediaUrl,
    } = useJustificationsList()

    return {
      avatarText,
      resolveUserRoleName,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      justificationsStatus,
      justificationsStatusColor,
      justificationsStatusIcon,
      parseDate,

      departments,
      mediaUrl,
      loadingCarousel: false,
      months,
      currentMonth,
      previousMonth,
      currentYear: initialYear,
      minToDay: null,
      fromDay,
      toDay,
      types,
      users,
      observer: null,
      limit: 10,
      search: '',
      selectedMonth: initialMonth,
      statusOptions,
      mediaEndpoint,
      multipleDay: false,
      updatedMultipleDay: false,
      updatedAllDay: false,
      allDay: false,
      loading: false,
      justifications,
      selectedJustification: null,
      filterType: null,
      filterStatus: null,
      filterDepartment,
      filterSubDepartment,
      subDepartments,
      approveType: null,
      listKey: 0,
      updatedJustification: {
        type: null,
        status: null,
        user_id: null,
        start_day: null,
        start_time: null,
        end_day: null,
        end_time: null,
        all_day: 0,
        note: null,
        manager_note: null,
      },
      updatedJustificationUser: null,
      updatedJustificationType: null,
      newJustification: {
        type: null,
        status: 'approved',
        approvation_type: 'admin',
        user_id: null,
        start_day: null,
        start_time: null,
        end_day: null,
        end_time: null,
        all_day: 0,
        note: null,
        manager_note: null,
      },
    }
  },
  computed: {
    usersFiltered() {
      return this.users.filter(data => JSON.stringify(data).toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
    },
    usersPaginated() {
      return this.usersFiltered.slice(0, this.limit)
    },
    hasNextPage() {
      return this.usersPaginated.length < this.usersFiltered.length
    },
  },
  mounted() {
    this.$refs.monthSlider.setSlide(this.currentMonth)
    this.loadingCarousel = true
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    refetchJustifications() {
      let departmentId = null
      if (this.filterSubDepartment) departmentId = this.filterSubDepartment.value
      else if (this.filterDepartment) departmentId = this.filterDepartment.value
      store
        .dispatch('app-justifications/fetchJustifications', {
          type: this.filterType ? this.filterType.value : null,
          status: this.filterStatus ? this.filterStatus.value : null,
          department_id: departmentId,
          sort_by: 'created_at',
          sort_direction: 'DESC',
          from_start_day: this.fromDay,
          to_start_day: this.toDay,
          per_page: 1000,
          page: 0,
        })
        .then(response => {
          this.justifications = response.data[0]
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            router.replace({ name: 'auth-login' }).then(() => {
              toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Sessione scaduta',
                  icon: 'AlertTriangleIcon',
                  text: "Sessione scaduta, effettuare di nuovo l'accesso per utilizzare tutte le funzionalità",
                  variant: 'danger',
                },
              })
            })
          }
        })
      this.listKey++
    },
    dateDisabledNew(ymd) {
      if (ymd <= this.newJustification.start_day) return true
      return false
    },
    dateDisabledUpdate(ymd) {
      if (ymd <= this.updatedJustification.start_day) return true
      return false
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose() {
      this.observer.disconnect()
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const { scrollTop } = target.offsetParent
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
    setType(e) {
      this.newJustification.type = e ? e.value : null
    },
    setUpdatedType(e) {
      this.updatedJustification.type = e ? e.value : null
    },
    selectUser(e) {
      this.newJustification.user_id = e ? e.value : null
    },
    selectUpdatedUser(e) {
      this.updatedJustification.user_id = e ? e.value : null
    },
    setUpdatedJustificationMultipleDay(justification) {
      const fromDay = moment(justification.start_day).format('YYYY-MM-DD')
      const endDay = moment(justification.end_day).format('YYYY-MM-DD')
      this.updatedMultipleDay = false
      if (endDay > fromDay) this.updatedMultipleDay = true
    },
    createJustification() {
      if (this.newJustification.start_time == '') this.newJustification.start_time = null
      if (this.newJustification.end_time == '') this.newJustification.end_time = null
      const valid = this.validation('create')
      if (!valid) return
      this.loading = true
      if (!this.multipleDay) {
        this.newJustification.end_day = this.newJustification.start_day
      }
      if (this.newJustification.start_time == '') this.newJustification.start_time = null
      if (this.newJustification.end_time == '') this.newJustification.end_time = null
      this.newJustification.all_day = 0
      if (this.allDay === true) {
        this.newJustification.start_time = null
        this.newJustification.end_time = null
        this.newJustification.all_day = 1
      }
      if (this.newJustification.start_time) this.newJustification.start_time = this.newJustification.start_time.substring(0, 5)
      if (this.newJustification.end_time) this.newJustification.end_time = this.newJustification.end_time.substring(0, 5)
      store
        .dispatch('app-justifications/addJustification', this.newJustification)
        .then(result => {
          this.refetchJustifications()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Giustificativo #${result.data.id} aggiunto con successo`,
              icon: 'ClipboardIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error === 400) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Errore durante la creazione della Richiesta Ferie',
                icon: 'ClipboardIcon',
                variant: 'danger',
              },
            })
          }
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
      this.loading = false
      this.$bvModal.hide('create-modal')
    },
    updateJustification() {
      if (this.updatedJustification.start_time == '') this.newJustupdatedJustificationification.start_time = null
      if (this.updatedJustification.end_time == '') this.updatedJustification.end_time = null
      const valid = this.validation('update')
      if (!valid) return
      this.loading = true
      const { id } = this.updatedJustification
      const formData = {
        type: this.updatedJustification.type,
        user_id: this.updatedJustification.user_id,
        start_day: this.updatedJustification.start_day,
        start_time: this.updatedJustification.start_time ? this.updatedJustification.start_time.substring(0, 5) : null,
        end_day: this.updatedMultipleDay == true ? this.updatedJustification.end_day : this.updatedJustification.start_day,
        end_time: this.updatedJustification.end_time ? this.updatedJustification.end_time.substring(0, 5) : null,
        all_day: this.updatedAllDay == true ? 1 : 0,
        note: this.updatedJustification.note,
        manager_note: this.updatedJustification.manager_note,
      }
      store
        .dispatch('app-justifications/updateJustification', { id, formData })
        .then(result => {
          this.refetchJustifications()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Giustificativo modificato con successo',
              icon: 'ClipboardIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
      this.loading = false
      this.$bvModal.hide('update-modal')
    },
    updateNote(id, index) {
      const formData = {
        manager_note: this.justifications[index].manager_note,
      }
      store
        .dispatch('app-justifications/updateJustification', { id, formData })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Note Admin modificate con successo',
              icon: 'ClipboardIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
    deleteJustification(id) {
      store
        .dispatch('app-justifications/deleteJustification', { id })
        .then(() => {
          this.selectedJustification = null
          this.refetchJustifications()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Giustificativo #${id} eliminato con successo`,
              icon: 'ClipboardIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
    selectApprovation(id) {
      if (!this.approveType) {
        return this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Tipo',
            icon: 'AlertTriangleIcon',
            text: 'Inserire il tipo di Approvazione per proseguire.',
            variant: 'danger',
          },
        })
      }
      if (this.approveType == 'admin') this.approveJustification(id)
      if (this.approveType == 'reject') this.rejectJustification(id)
      if (this.approveType == 'two_managers') this.managersApproveJustification(id)
    },
    approveJustification(id) {
      store
        .dispatch('app-justifications/approveJustification', { id })
        .then(() => {
          this.refetchJustifications()
          this.$bvModal.hide('approve-modal')
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Giustificativo Approvato con successo',
              icon: 'ClipboardIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
    rejectJustification(id) {
      store
        .dispatch('app-justifications/rejectJustification', { id })
        .then(() => {
          this.refetchJustifications()
          this.$bvModal.hide('approve-modal')
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Giustificativo rifiutato con successo',
              icon: 'ClipboardIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
    managersApproveJustification(id) {
      const formData = {
        approvation_type: 'two_managers',
      }
      store
        .dispatch('app-justifications/updateJustification', { id, formData })
        .then(() => {
          this.refetchJustifications()
          this.$bvModal.hide('approve-modal')
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Approvazione Responsabili avviata con successo',
              icon: 'ClipboardIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
    getPreviousMonth() {
      if (this.selectedMonth) this.previousMonth = this.selectedMonth
    },
    handleSlideStart() {
      // Verifica se si sta scorrendo all'indietro (frecce sinistra)
      if (this.$refs.monthSlider.direction === 'prev') {
        if (this.selectedMonth === 0) {
          // Riduci l'anno se sei a gennaio
          this.currentYear--
          this.selectedMonth = 11 // Imposta il mese a dicembre
        } else {
          this.selectedMonth--
        }
      }
      // Verifica se si sta scorrendo in avanti (frecce destra)
      else if (this.$refs.monthSlider.direction === 'next') {
        if (this.selectedMonth === 11) {
          // Aumenta l'anno se sei a dicembre
          this.currentYear++
          this.selectedMonth = 0 // Imposta il mese a gennaio
        } else {
          this.selectedMonth++
        }
      }

      // Aggiorna le variabili di data in base al mese e all'anno correnti
      const fromDate = moment([this.currentYear, this.selectedMonth])
      const toDate = fromDate.clone().endOf('month')
      this.fromDay = fromDate.format('YYYY-MM-DD')
      this.toDay = toDate.format('YYYY-MM-DD')
      this.refetchJustifications()
    },
    validation(type) {
      let object = this.newJustification
      let { allDay } = this
      let { multipleDay } = this
      if (type == 'update') {
        object = this.updatedJustification
        allDay = this.updatedAllDay
        multipleDay = this.updatedMultipleDay
      }
      if (!object.type) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Tipo',
            icon: 'AlertTriangleIcon',
            text: 'Inserire il tipo di Giustificativo per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      if (!object.user_id) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Utente',
            icon: 'AlertTriangleIcon',
            text: "Selezionare l'utente del Giustificativo per proseguire.",
            variant: 'danger',
          },
        })
        return false
      }
      if (!object.start_day) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Data',
            icon: 'AlertTriangleIcon',
            text: "Selezionare la data d'inizio del Giustificativo per proseguire.",
            variant: 'danger',
          },
        })
        return false
      }
      if (!allDay && !object.start_time) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Ora',
            icon: 'AlertTriangleIcon',
            text: "Selezionare l'ora d'inizio del Giustificativo per proseguire.",
            variant: 'danger',
          },
        })
        return false
      }
      if (multipleDay && !object.end_day) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Data',
            icon: 'AlertTriangleIcon',
            text: 'Selezionare la data di fine del Giustificativo per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      if (!allDay && !object.end_time) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Ora',
            icon: 'AlertTriangleIcon',
            text: "Selezionare l'ora di fine del Giustificativo per proseguire.",
            variant: 'danger',
          },
        })
        return false
      }
      if (multipleDay && (object.end_day <= object.start_day)) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Date',
            icon: 'AlertTriangleIcon',
            text: 'Nei Giustificativi relativi a più giorni la data di Fine deve essere almeno un giorno dopo la data di Inizio.',
            variant: 'danger',
          },
        })
        return false
      }
      return true
    },
  },
}
</script>

<style lang="scss">
#month-slider,
.carousel-inner,
.carousel-item {
  background: transparent !important;
  height: 30px;
}
#month-slider .carousel-item > img {
  height: 100%;
}

#month-slider .carousel-caption {
  bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  h2 {
    margin-bottom: 0;
  }
}
</style>
<style lang="scss" scoped>
.card-footer {
  text-align: center;
  padding: 5px;
}
.rounded-lg textarea.form-control {
  background-color: #161d31;
  border-color: #161d31;
  border-radius: 30px;
  padding: 1rem;
}
.rounded-lg textarea.form-control:not(:focus) {
  border-color: #161d31;
}
.loader {
  text-align: center;
  color: #bbbbbb;
}
</style>
