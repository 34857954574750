import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchJustifications(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/justifications', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchJustification(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/justifications/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    updateJustification(ctx, { id, formData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/justifications/${id}`, formData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addJustification(ctx, justificationData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/justifications', justificationData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    deleteJustification(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/justifications/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    approveJustification(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/justifications/approve/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    rejectJustification(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/justifications/reject/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchJustificationTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/justification-types', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addJustificationType(ctx, justificationTypeData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/justification-types', justificationTypeData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    updateJustificationType(ctx, { id, formData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/justification-types/${id}`, formData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    deleteJustificationType(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/justification-types/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
  },
}
